﻿// bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';

// noty
import 'noty/lib/noty.css';
import 'noty/lib/themes/bootstrap-v4.css';

// airdatepicker
import 'air-datepicker/dist/css/datepicker.min.css';

// toastify
import 'toastify-js/src/toastify.css';

// choices
import 'choices.js/public/assets/styles/choices.css';

// glide js
import '@glidejs/glide/dist/css/glide.core.min.css';
import '@glidejs/glide/dist/css/glide.theme.min.css';

// custom css
import '../styling/ecommerce.scss';
import '../styling/ui.scss';
import '../styling/utils.css';
import '../styling/index.scss';